<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <b-table
      hover
      responsive="sm"
      :items="items"
      selectable
      selectMode="single"
      @row-selected="onRowSelected"
    />
  </b-card>

</template>

<script>
import {
  BCard, BTable,
} from 'bootstrap-vue'
import { mapState } from 'vuex';

export default {
  components: {
    BCard,
    BTable,
  },
  data: () => ({
      // projects: JSON.parse(localStorage.getItem("projects"))
  }),
  computed: {
	    ...mapState('projects', ['projects']),
      items() {
  				let items = [];
  				this.projects.forEach((item) => {
  					let elem = {
  							key: item.key,
                name: item.name,
                lead: `${item.lead.firstname} ${item.lead.lastname}`
            }
            items.push(elem);
          });
  				return items;
      },
  },
  methods: {
		  onRowSelected(items) {
          this.$router.push({ path: `/project/${items[0]['key']}/board?from=project` });
      },
  }
}
</script>

