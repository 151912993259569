<template>
  <section>
    <b-row>
      <b-col cols="12">
        <project-list />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { initApi } from '@/api';
import { BRow, BCol } from 'bootstrap-vue';
import ProjectList from './project-list/ProjectList.vue';

export default {
  name: 'Projects',
  components: {
    ProjectList,
    BRow,
    BCol,
  },
  methods: {
    loadData() {
      initApi.config().then((user) => {
        // обновляем общие права
        const permissions = user.data.config?.permissions.map(({ available, context }) => ({
          action: available,
          subject: context,
        }));
        console.log('permissions: ', permissions);
        this.$ability.update([
          ...permissions,
          {
            action: 'read',
            subject: 'Auth',
          },
        ]);
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
